import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPolicies } from '../../redux/slices/policy'
import './footer.css'
const Footer = () => {
  const dispatch = useDispatch()

  const { policies } = useSelector((state) => state.policy)

  useEffect(() => {
    dispatch(getPolicies())
  }, [])

  console.log(policies, 'hkhk')

  return (
    <footer
      className="footer dark-footer dark-bg"
      style={{ maxWidth: '100vw' }}
    >
      <div className="footer-copyright">
        <p>
          Copyright@ 2021 Yardcan Maintained By{' '}
          <a href="http://riolabz.com/">riolabz</a>
        </p>

        {
          <div className="footer-links">
            {policies.map((policy) => {
              return (
                <Link
                  key={policy.uuid}
                  to={`/policy/${policy.uuid}`}
                  className="footer-link"
                >
                  {policy.title}
                </Link>
              )
            })}
          </div>
        }
      </div>
    </footer>
  )
}
export default Footer
